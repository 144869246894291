import { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AuthContext } from "../context/auth-context";
import RequireAuth from "./require-auth";
import LoginPage from "../routes/LoginPage";
import HostsPage from "../routes/HostsPage";
import LoadingScreen from "./LoadingScreen";
import "./App.css";
import ActionsPage from "../routes/ActionsPage";
import HostPage from "../routes/HostPage";
import ReportsPage from "../routes/ReportsPage";
import PromoCodesPage from "../routes/PromoCodesPage";

function App() {
  const { currentUser, isLoading } = useContext(AuthContext);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Routes>
      {!currentUser ? (
        <>
          <Route index element={<LoginPage />} />
          <Route path="hosts" element={<Navigate replace to="/" />} />
        </>
      ) : (
        <>
          <Route index element={<Navigate replace to="/hosts" />} />
          <Route
            path="hosts"
            element={
              <RequireAuth>
                <HostsPage />
              </RequireAuth>
            }
          />
          <Route
            path="actions"
            element={
              <RequireAuth>
                <ActionsPage />
              </RequireAuth>
            }
          />
          <Route
            path="hosts/:id"
            element={
              <RequireAuth>
                <HostPage />
              </RequireAuth>
            }
          />
          <Route
            path="reports"
            element={
              <RequireAuth>
                <ReportsPage />
              </RequireAuth>
            }
          />
          <Route path="promocodes" element={
            <RequireAuth>
              <PromoCodesPage />
            </RequireAuth>
          } />
        </>
      )}
    </Routes>
  );
}

export default App;
